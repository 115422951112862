import React from "react";
import Tag from "./tag";

export default function Tags({ tags, tagSlugs }) {
  return (
    <div className="flex flex-row space-x-3">
      {tags.map((tag, i) => (
        <Tag tag={tag} slug={tagSlugs[i]} />
      ))}
    </div>
  );
}
