import React from "react";
import { Link } from "gatsby";

import { TAG_COLORS } from "../../constants";

export default function Tag(props) {
  const color =
    TAG_COLORS[props.tag] === undefined
      ? TAG_COLORS["DEFAULT"]
      : TAG_COLORS[props.tag];
  return (
    <Link to={props.slug} className="inline-block">
      <span
        className={`${color.bg} ${color.text} inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium`}
      >
        {props.tag}
      </span>
    </Link>
  );
}
