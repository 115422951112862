import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";

const Header = ({ tag: T, size, children }) => (
  <T className={`flex flex-row mt-6 mb-2 ${size}`}>{children}</T>
);

const mdxComponents = {
  h1: ({ children }) => (
    <Header tag="h1" size="text-2xl">
      {children}
    </Header>
  ),
  h2: ({ children }) => (
    <Header tag="h2" size="text-xl">
      {children}
    </Header>
  ),
  h3: ({ children }) => (
    <Header tag="h3" size="text-lg">
      {children}
    </Header>
  ),
  h4: ({ children }) => (
    <Header tag="h4" size="text-md font-bold">
      {children}
    </Header>
  ),
  h5: ({ children }) => (
    <Header tag="h5" size="text-md italic">
      {children}
    </Header>
  ),
  h6: ({ children }) => (
    <Header tag="h6" size="text-md italic">
      {children}
    </Header>
  ),
  ul: ({ children }) => (
    <ul className="p-2 list-disc list-inside">{children}</ul>
  ),
  ol: ({ children }) => (
    <ol className="p-2 list-decimal list-inside">{children}</ol>
  ),
  p: ({ children }) => <p className="py-2">{children}</p>,
  blockquote: ({ children }) => (
    <div className="relative my-4 bg-gray-50 border-l-8 border-primary-300">
      <div className="absolute left-4 top-4 text-6xl text-primary-300">
        &#8220;
      </div>
      <blockquote className="py-6 px-16 italic text-lg">{children}</blockquote>
    </div>
  ),
};

const ProvideAndRenderMDX = ({ mdx }) => (
  <MDXProvider components={mdxComponents}>
    <MDXRenderer>{mdx}</MDXRenderer>
  </MDXProvider>
);

export default ProvideAndRenderMDX;
