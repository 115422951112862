import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

export default function Author(props) {
  return (
    <div className="my-4 flex items-center">
      <div className="flex-shrink-0">
        <span className="sr-only">{props.author}</span>
        <GatsbyImage
          className="h-14 w-14 rounded-full"
          image={props.profilePicture}
          alt={props.author}
        />
      </div>
      <div className="ml-3">
        <p className="text-sm font-medium text-gray-900">{props.author}</p>
        <div className="flex space-x-1 text-sm text-gray-500">
          <time
            dateTime={new Date(props.date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          >
            {new Date(props.date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
            })}
          </time>
          <span aria-hidden="true">&middot;</span>
          <span>{props.readingTime} read</span>
        </div>
      </div>
    </div>
  );
}
