import React from "react";
import ProvideAndRenderMDX from "../mdx";
import { useProfilePicture } from "../../hooks";
import Author from "./author";
import Tags from "./tags";

const Post = ({ post }) => {
  const { body } = post;
  const { tagSlugs } = post.fields;
  const { tags, title, date, author, readingTime } = post.frontmatter;
  const profilePicture = useProfilePicture(author);

  return (
    <div className="pb-16 max-w-2xl mx-auto">
      <div className="py-8">
        <h1 className="pb-8 text-center font-bold text-5xl">{title}</h1>
        <ProvideAndRenderMDX mdx={body} />
      </div>
      <hr class="solid" />
      <Author
        author={author}
        date={date}
        profilePicture={profilePicture}
        readingTime={readingTime}
      />
      {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}
    </div>
  );
};

export default Post;
