const TAG_COLORS = {
  Company: { bg: "bg-primary-100", text: "text-primary-800" },
  Analytics: { bg: "bg-indigo-100", text: "text-indigo-800" },
  Data: { bg: "bg-green-100", text: "text-green-800" },
  Accessibility: { bg: "bg-yellow-100", text: "text-yellow-800" },
  //   red: { bg: "bg-red-100", text: "text-red-800" },
  DEFAULT: { bg: "bg-gray-100", text: "text-gray-800" },
};

export default TAG_COLORS;
